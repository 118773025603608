<template>
  <div>
    <div
      v-if="!error"
      class="container text-left"
      v-html="microarrayExperimentContent"
    ></div>
    <div v-else>
      <PageNotFound />
    </div>
  </div>
</template>

<script>
//http://localhost:8082/browse/microarray_experiments/ABA%20time%20course%20in%20wildtype%20seedlings
import PostsService from "@/services/PostsService";
import PageNotFound from "@/components/common/PageNotFound";
import PremiumIndicator from "@/components/common/PremiumIndicator";
export default {
  name: "MicroarrayExperiment",
  components: {
    PageNotFound,
    PremiumIndicator,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_S3_PATH + "microarrayexperiment/",
      microarrayExperimentContent: "",
      loading: false,
      error: false,
    };
  },
  mounted() {
    document.title = "Microarray Experiments";
    const urlPath = window.location.pathname;
    const pathSegments = urlPath.split("/");
    const experimentKey = pathSegments[pathSegments.length - 1];
    this.fetchMicroarrayExperimentContent(experimentKey);
  },
  methods: {
    async fetchMicroarrayExperimentContent(experimentKey) {
      // Format the experimentKey to replace spaces with '+'
      const formattedKey = experimentKey.replace(/\s+/g, "+");
      try {
        this.loading = true;
        let response = await PostsService.getMicroarrayExperimentDetail({
          key: formattedKey,
        });
        this.microarrayExperimentContent = response.data;
        this.microarrayExperimentContent =
          this.microarrayExperimentContent.replace(
            '<img src="\/images\/view_all\.gif" usemap="#file_map" border="0">',
            ""
          );
        document.title = "Microarray Experiment - " + experimentKey + " - TAIR";
        this.loading = false;
      } catch (error) {
        console.error("Error fetching microarray experiment content:", error);
        this.error = true;
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.container {
  width: 100vw; /* 100% of the viewport width */
  padding: 20px; /* Optional: adjust as needed */
  box-sizing: border-box; /* Includes padding in height and width */
}
</style>
